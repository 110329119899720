import css from 'styled-jsx/css';
import colors from '../../constants/colors';

export default css`
    *{
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
    h6 {
        font-size: 16px;
        font-weight: bold;
    }
    section{
        width: 100%;
    }
    .userLinks {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .footerLinks {
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
        color: white;
        text-decoration: none;
    }
`;
