/* global window, document */

import React, { PureComponent } from 'react';
const config = require('../config').default;

const withMaps = (ComposedComponent) => {
  class GoogleMap extends PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        loaded: false,
        maps: null,
      };
    }

    componentDidMount() {
      this.initSDK();
    }

    initSDK() {
      if (document.getElementById(config.google.maps.element)) {
        if (window.google && window.google.maps) {
          this.setState(Object.assign({}, this.state, {
            loaded: true, maps: window.google.maps,
          }));
        }
        return;
      }

      if (!window.initMap) {
        window.initMap = () => {
          if (!window.google || !window.google.maps) {
            return;
          }

          this.setState(Object.assign({}, this.state, {
            loaded: true, maps: window.google.maps,
          }));
        };
      }

      let ready = false;

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = config.google.maps.element;
      script.src = `${config.google.maps.api}?key=${config.google.maps.key}&libraries=places`;

      const onLoad = () => {
        if (!ready && (!this.readyState || this.readyState === 'complete')) {
          ready = true;
          window.initMap();
        }
      };

      script.onload = onLoad;
      script.onreadystatechange = onLoad;

      const scriptReference = document.getElementsByTagName('script')[0];
      scriptReference.parentNode.insertBefore(script, scriptReference);
    }

    render() {
      return (
        <ComposedComponent maps={this.state.maps} {...this.props} />
      );
    }
  }

  return GoogleMap;
};

export default withMaps;
