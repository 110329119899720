import React from 'react';
import styles from './styles';

const LandingSection = ({ t, alignment, header, subheader, content, image, }) => (
  <>
  <section className={'inline-image-right'}>
    {alignment === 'right' &&
      <div className={'container'}>
        <div className={'row'}>
          <div className={'colsm6 colsm6 alignvertical no-align-mobile'}>
            <h1>{t(header)}</h1>
            <h6>{t(subheader)}</h6>
            <p className="lead">{t(content)}</p>
          </div>
          <div className={'colsm6 colsm6 text-center'}>
            <img alt="Product" className="product-image" src={image} />
          </div>
        </div>
      </div>
    }
    {alignment === 'left' &&
      <div className={'container'}>
        <div className={'row'}>
          <div className={'colsm6 colsm6 text-center'}>
            <img alt="Product" className="product-image" src={image} />
          </div>
          <div className={'colsm6 colsm6 alignvertical no-align-mobile'}>
            <h1>{t(header)}</h1>
            <h6>{t(subheader)}</h6>
            <p className="lead">{t(content)}</p>
          </div>
        </div>
      </div>
    }
    <style jsx>{styles}</style>
  </section>
  </>
);

LandingSection.defaultProps = {
  alignment: 'right',
  header: 'landing_product_1_header',
  subheader: 'landing_product_1_subheader',
  content: 'landing_product_1_content',
  image: 'static/imgs/landing_product_1.png',
};

export default LandingSection;
