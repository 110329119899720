import css from 'styled-jsx/css';

export default css`
*{
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
    section{
        padding-top: 80px;
        padding-bottom: 80px;
    }
`;
