import React, { PureComponent } from 'react';
import Tappable from 'react-tappable';
import PropTypes from 'prop-types';
import { connect } from '@pracman/store';
import { updateLocation } from '@pracman/store/lib/actions/location';
import styles from './styles';

import SVGIcon from '../SVGIcon/SVGIcon';
import withMaps from '../../hoc/withMaps';
import Logger from '../../utils/logger';
import Location from '../../utils/location';
import Geocoder from '../../utils/geocoder';
import { LocationIcon, CloseIcon } from '../../constants/icons';

class LocationSearch extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.props.state;
    this.state.location = {
      latitude: 47.3769,
      longitude: 8.5417,
    };

    this.autoComplete = null;
    this.autoCompleteElement = null;

    this.onPlaceChange = this.onPlaceChange.bind(this);
    this.handleTrackTap = this.handleTrackTap.bind(this);
    this.clearLocality = this.clearLocality.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }

  componentDidMount() {
    if (this.props.maps) {
      this.setupLocationPicker(this.props.maps);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.maps && nextProps.maps) {
      this.setupLocationPicker(nextProps.maps);
    }
  }

  onPlaceChange() {
    const place = this.autoComplete.getPlace();

    if (!place.geometry) {
      return;
    }

    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();
    const address = Geocoder.parseAddress(place);

    this.setState(Object.assign({}, this.state, {
      location: { latitude, longitude }, locality: Geocoder.getCompleteLocality(address),
    }));
    this.props.setLocation({ latitude, longitude });
  }

  setupLocationPicker(maps) {
    if (!this.autoCompleteElement || !maps) {
      return;
    }

    this.autoComplete = new maps.places.Autocomplete(this.autoCompleteElement);
    maps.event.addListener(this.autoComplete, 'place_changed', this.onPlaceChange);
  }

  handleLocationChange(e) {
    this.setState(Object.assign({}, this.state, {
      locality: e.target.value,
    }));
    if(e.target.value === '') {
      this.props.disableButton();
    } else {
      this.props.enableButton();
    }
    //this.props.setLocation(e.target.value);
    console.log("LOCALITY");
    console.log(this.state.locality);
  }

  async handleTrackTap(e) {
    e.preventDefault();
    e.stopPropagation();

    try {
      const location = await Location.getCurrentLocation();
      const geocodedLocation = await Geocoder.reverseGeocode(this.props.maps, location);
      const parsedLocation = Geocoder.parseAddress(geocodedLocation);

      this.props.updateLocation(location);

      this.setState(Object.assign({}, this.state, {
        location, locality: Geocoder.getCompleteLocality(parsedLocation),
      }));
      this.props.enableButton();
      let userLocation = {};
      userLocation.longitude = geocodedLocation.geometry.viewport.ga.l;
      userLocation.latitude = geocodedLocation.geometry.viewport.na.l;
      this.props.setLocation(userLocation);
    } catch (error) {
      Logger.warn(error.message);
    }
  }

  clearLocality(e) {
    e.preventDefault();
    e.stopPropagation();

    try {
      const location = '';

      this.props.updateLocation(location);

      this.setState(Object.assign({}, this.state, {
        location, locality: '',
      }));
      this.props.disableButton();
    } catch (error) {
      Logger.warn(error.message);
    }
  }

  render() {
    return (
      <div className="location_search">
        <div className="search_wrapper">
          <div className="icon_wrapper">
            <Tappable onTap={this.handleTrackTap}>
              <div className="SVG_Icon">
                <SVGIcon icon={LocationIcon} />
              </div>
            </Tappable>
          </div>
          <div className="input_wrapper">
            <input
              className="input home-input"
              type="text"
              placeholder={this.props.t('label_location_placeholder')}
              value={this.state.locality}
              ref={(e) => { this.autoCompleteElement = e; }}
              onChange={this.handleLocationChange}
            />
          </div>
          { this.state.locality !== '' &&
            <div className="icon_wrapper">
              <Tappable onTap={this.clearLocality}>
                <div className="SVG_Icon">
                  <SVGIcon icon={CloseIcon} />
                </div>
              </Tappable>
            </div>
          }
        </div>
        <style jsx>
          {styles}
        </style>
      </div>
    );
  }
}

LocationSearch.defaultProps = {
  maps: null,
  t: '',
};

LocationSearch.propTypes = {
  state: PropTypes.object.isRequired,
  setLocation: PropTypes.func.isRequired,
  updateLocation: PropTypes.func.isRequired,
  maps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  t: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  updateLocation: (location) => {
    dispatch(updateLocation(location));
  },
});

export default connect(null, mapDispatchToProps)(withMaps(LocationSearch));
