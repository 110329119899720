import React from 'react';
import styles from './styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import * as moment from 'moment';
import * as config from '../../config';

class LandingFooter extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      contactModal: false,
    }
  }

  toggleModal() {
    this.setState({
      contactModal: !this.state.contactModal,
    });
  }

  render() {
    const { t } = this.props;

    return (
      <>
      <div className='footercontainer'>
        <footer className={'bgprimary short2'}>
          <div className={'container'}>
            <div className={'row'}>
              <div className="col-sm-11">
                <span className={'textwhite'}>© {moment().format('YYYY')} PRACMAN AG, Zurich, Switzerland</span>
                <span className={'textwhite'} />
                <span className={'textwhite'} />
                <span className={'textwhite'} />
              </div>
            </div>
          </div>

          <div className={'contactaction'} style={{cursor: 'pointer'}}>
            <div onClick={() => this.toggleModal()} className={'alignvertical'}>
              <span className={'textwhite'}>{t('landing_get_in_touch')}</span>
            </div>
          </div>
        </footer>
        <Dialog
          open={this.state.contactModal}
          onClose={() => this.toggleModal()}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth='lg'
        >
          <DialogContent>
            <div className="modalContainer">
              <iframe className="contactFrame" src={config.faqUrl}></iframe>
            </div>
          </DialogContent>
        </Dialog>
        <style jsx>{styles}</style>
      </div>
      </>
    );
  }
}


export default LandingFooter;
