/* global window */

const Promise = require('bluebird');

const Location = {
  getCurrentLocation: () =>
    new Promise((resolve, reject) => {
      if (!window) {
        return reject(new Error('Can\'t find window!'));
      }

      if (!('geolocation' in window.navigator)) {
        return reject(new Error('Geolocation is not supported!'));
      }

      return window
        .navigator
        .geolocation
        .getCurrentPosition(
          position =>
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            }),
          (error) => {
            let message = 'Unable to get your location.';
            switch (error.code) {
              case error.PERMISSION_DENIED:
                message = 'Please allow us to detect your location.';
                break;
              case error.UNKNOWN_ERROR:
                message = 'An unknown error occurred.';
                break;
              case error.POSITION_UNAVAILABLE:
              case error.TIMEOUT:
              default:
                message = 'Unable to get your location.';
                break;
            }

            return reject(new Error(message));
          }, { enableHighAccuracy: true });
    }),
};

export default Location;
