import Promise from 'bluebird';

export default {
  reverseGeocode: (maps, location) => (
    new Promise((resolve, reject) => {
      if (!maps || !location.latitude || !location.longitude) {
        reject(new Error('Invalid arguments: maps / location is not defined!'));
      }

      const geocoder = new maps.Geocoder();

      const latLng = new maps.LatLng(location.latitude, location.longitude);

      return geocoder.geocode({ latLng }, (results, status) => {
        if (status !== 'OK') {
          return reject(new Error(`Unable to find address: ${status}`));
        }

        return resolve(results[0]);
      });
    })
  ),

  parseAddress: (address) => {
    const location = {
      establishment: '',
      street_address: '',
      sublocality: '',
      locality: '',
      administrative_area_1: '',
      administrative_area_2: '',
      country: '',
      postal_code: '',
    };

    if (!address.address_components) return location;

    address.address_components.forEach((component) => {
      if (component.types.indexOf('establishment') >= 0) {
        location.establishment = component.long_name;
      }

      if (component.types.indexOf('street_address') >= 0) {
        location.street_address = component.long_name;
      }

      if (component.types.indexOf('sublocality') >= 0) {
        location.sublocality = component.long_name;
      }

      if (component.types.indexOf('locality') >= 0) {
        location.locality = component.long_name;
      }

      if (component.types.indexOf('administrative_area_level_2') >= 0) {
        location.administrative_area_2 = component.long_name;
      }

      if (component.types.indexOf('administrative_area_level_1') >= 0) {
        location.administrative_area_1 = component.long_name;
      }

      if (component.types.indexOf('country') >= 0) {
        location.country = component.long_name;
      }

      if (component.types.indexOf('postal_code') >= 0) {
        location.postal_code = component.long_name;
      }
    });

    return location;
  },

  getCompleteLocality: (address) => {
    const location = [];
    if (address.sublocality && address.sublocality.length > 0) {
      location.push(address.sublocality);
    }

    if (address.locality && address.locality.length > 0) {
      location.push(address.locality);
    }

    if (address.administrative_area_1 && address.administrative_area_1.length > 0) {
      location.push(address.administrative_area_1);
    }

    if (address.country && address.country.length > 0) {
      location.push(address.country);
    }

    return location.join(', ');
  },
};
