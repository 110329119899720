import React from 'react';
import { connect } from 'react-redux';
import styles from './styles';
import { Link } from '../../../routes';
import appModalTypes from '../../constants/authModalTypes';
import appActionTypes from "../../types/app";
import { resetUser } from '../../actions/app';

const LandingPreFooter = ({ t, authModalChange}) => (
  <>
  <section className={'primaryfeatures duplicatablecontent'}>
    <div className={'container'}>
      <div className={'row'}>

        <div className={'colsm6 clearfix colmd4'}>
          <div className={'feature featureiconsmall'}>

            <h6 className={'textwhite'}>Therapeutenbuchen.ch</h6>
            <p className={'textwhite'}>
            PRACMAN AG<br />Limmatquai 120<br />8001 Zürich<br />Switzerland
            </p>
          </div>
        </div>

        <div className={'colsm6 clearfix colmd4'}>
          <div className={'feature featureiconsmall userLinks'}>
            <button className="menu_option highlight truncate no_button" onClick={() => authModalChange(appModalTypes.signUp)}>
              <a className="footerLinks">{t('landing_signup')}</a>
            </button>
            <button className="menu_option highlight truncate no_button" onClick={() => authModalChange(appModalTypes.signIn)}>
              <a className="footerLinks">{t('landing_login')}</a>
            </button>
            <button className="menu_option highlight truncate no_button">
              <a className="footerLinks" href="https://account.itherapeut.ch/" target="_blank">{t('landing_register')}</a>
            </button>
          </div>
        </div>

        <div className={`colsm6 clearfix colmd4`}>
          <div className={`feature featureiconsmall`}>
            <a className="footerLinks" href="https://softrey.ch/dsg/Softrey-DSE-Sep-2023-DE.pdf">
              {t('landing_privacy_policy')}
            </a>
            <p></p>
            <a className="footerLinks" href="https://softrey.ch/dsg/Softrey-AGB-Sep-2023-DE.pdf">
              {t('landing_legal')}
            </a>
            <Link route="/info/help">
              <p className="footerLinks">{t('landing_help')}</p>
            </Link>
          </div>
        </div>

      </div>

    </div>
    <style jsx>{styles}</style>
  </section>
  </>
);

const mapStateToProps = (state) => {
  return {
    user: state.app.user
  }
};

const mapDispatchToProps = (dispatch) => ({
  authModalChange: (name) => dispatch({ type: appActionTypes.AUTH_MODAL_CHANGE, payload: { name } }),
  resetUser: resetUser(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPreFooter);
