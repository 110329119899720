import React, { PureComponent } from 'react';
import Tappable from 'react-tappable';
import PropTypes from 'prop-types';
import styles from './styles';
import SVGIcon from '../SVGIcon/SVGIcon';
import Logger from '../../utils/logger';
import { SearchIcon, CloseIcon } from '../../constants/icons';


class ServiceSearch extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      service: "",
    };

    this.handleServiceChange = this.handleServiceChange.bind(this);
    this.clearService = this.clearService.bind(this);
  }

  handleServiceChange(e) {
    this.props.setSuggestions(true);
    console.log(this.props.suggestions);
    // this.setState(Object.assign({}, this.state, {
    //   service: e.target.value,
    // }));
    if (e.target.value) {
      this.setState(Object.assign({}, this.state, {
        service: e.target.value,
      }));
      this.props.getQuery(e.target.value);
      this.props.setService(e.target.value);
    } else {
      this.setState(Object.assign({}, this.state, {
        service: "",
      }));
      this.props.getQuery("");
      this.props.setService(" ");
    }
  }

  clearService(e) {
    e.preventDefault();
    e.stopPropagation();

    try {
      this.setState(Object.assign({}, this.state, {
        service: "",
      }));
      this.props.getQuery("");
      this.props.setService(" ");
    } catch (error) {
      Logger.warn(error.message);
    }
    this.props.setSuggestions(false);
  }

  render() {
    return (
      <div className="service_search">
        <div className="search_wrapper">
          <div className="icon_wrapper">
            <div className="SVG_Icon">
              <SVGIcon icon={SearchIcon} />
            </div>
          </div>
          <div className="input_wrapper">
            <input
              className="input home-input"
              type="text"
              placeholder={this.props.t('label_service_placeholder')}
              value={this.props.service}
              onChange={this.handleServiceChange}
            />
          </div>
          { this.state.service !== '' &&
            <div className="icon_wrapper">
              <Tappable onTap={this.clearService}>
                <div className="SVG_Icon">
                  <SVGIcon icon={CloseIcon} />
                </div>
              </Tappable>
            </div>
          }
        </div>
        <style jsx>
          {styles}
        </style>
      </div>
    );
  }
}

ServiceSearch.defaultProps = {
  t: '',
};

ServiceSearch.propTypes = {
  getQuery: PropTypes.func.isRequired,
  setService: PropTypes.func.isRequired,
  t: PropTypes.func,
};

export default ServiceSearch;
