import css from 'styled-jsx/css';

export default css`
*{
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  h1 {
    font-size: 32px;
    line-height: 44px;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    color: #333333;
    margin: 0px;
    margin-bottom: 16px;
  }
  h6{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 32px;
  }
  p{
      font-size: 16px;
  }
`;
