import css from 'styled-jsx/css';
import colors from '../../constants/colors';

export default css`
*{
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.icon-magnifying-glass{
    background-image: url(/static/imgs/landing_search.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .icon-browser{
    background-image: url(/static/imgs/landing_choose.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .icon-calendar{
    background-image: url(/static/imgs/landing_book.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .icon-network{
    background-image: url(/static/imgs/icon_network.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .icon-profile{
    background-image: url(/static/imgs/icon_profile.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .icon-free{
    background-image: url(/static/imgs/icon_free.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .icon-fav{
    background-image: url(/static/imgs/icon_fav.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .white-border{
    border-color: #ffffff;
  }
  .background-image{
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
  }
  .background-image-holder::after{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background: #000;
    opacity: 0.3;
    content: "";
  }
  .flex_circle_icons_centered{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .flex_circle_icons{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .custom_circle_icon{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 32px;
  }
  .svg_circle{
    width: 104px;
    height: 104px;
    border: #fff solid 2px;
    border-radius: 50%;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .svg_wrapper{
    color: #fff;
    font-size: 50px;
  }
  h1{
    font-size: 40px;
    line-height: 56px;
    font-weight: 600;
    margin-bottom: 24px;
  }
  h2{
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 40px;
  }
  .how{
      font-size: 32px;
      line-height: 44px;
      margin-bottom: 48px;
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    color: #333333;
  }
  .adv{
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 48px;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  color: white;
}
  h5 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
}
h3{
  font-size: 26px;
  line-height: 32px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
}
  `;
