/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';

import { Link } from '../../../routes';

const SuggestionDropdown = ({ suggestions, selectService }) => {
  if (suggestions.length <= 0) return null;

  return (
    <div id="suggestion_dropdown" className="suggestion_dropdown">
      {
        suggestions.map(suggestion => (
          <a id="suggestion_item_container" className="suggestion_item_container generic_link" key={suggestion.name + suggestion.route} onClick={() => selectService(suggestion)}>
            { suggestion.name }
          </a>
        ))
      }
      <style jsx>{styles}</style>
    </div>
  );
};

SuggestionDropdown.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    result_type: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    routeParams: PropTypes.object,
  })).isRequired,
};

export default SuggestionDropdown;
