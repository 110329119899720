import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import searchAPI from '../../interfaces/api/search';
import LocationSearch from '../LocationSearch';
import ServiceSearch from '../ServiceSearch';
import SuggestionDropdown from '../SuggestionDropdown';
import styles from './styles';
import { Link } from '../../../routes';
import handleError from '../../utils/handleError';
import * as _ from 'lodash';

class LandingHeader extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      service: "",
      location: {
        latitude: 47.3769,
        longitude: 8.5417,
      },
      provider: null,
      suggestions: [],
      headerImages: ['static/imgs/landing_header_1.jpg', 'static/imgs/landing_header_2.jpg', 'static/imgs/landing_header_3.jpg'],
      searchDisabled: true,
      showSuggestions: true,
    };

    this.setService = this.setService.bind(this);
    this.selectService = this.selectService.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.suggest = this.suggest.bind(this);
    this.setSuggestions = this.setSuggestions.bind(this);
    this.enableButton = this.enableButton.bind(this);
    this.disableButton = this.disableButton.bind(this);
  }

  setService(service) {
    this.setState({
      service : service
    });
    if(service.length > 3) {
      this.setState({
        showSuggestions: true,
      })
    }
    if(service.length < 2) {
      this.setState({
        suggestions: []
      });
    }
  }

  selectService(service) {
    this.setState({
      service : service.name,
      provider: service,
      location: {
        latitude: service.Address.lat,
        longitude: service.Address.lng,
      }
    });
    this.setSuggestions(false);
  }

  async setSuggestions(boolean) {
    await this.setState({
      showSuggestions: boolean,
    });
    console.log(boolean, this.state.showSuggestions);
  }

  setLocation(location) {
    this.setState({ location });
  }

  //todo check or complet
  // took out Google stuff and foramting for the moment
  async suggest(query) {
    try {
      if (query.length < 3) {
        this.setState({ suggestions: [] });
        return;
      }

      const suggestions = await searchAPI.search(query, this.state.location);
      console.log('suggestions ', suggestions);
      if(_.isEmpty(suggestions)) {
        console.log('is empty');
        this.setState({ suggestions: [] });
        return;
      }
      
      // const formattedSuggestions = _.map(suggestions, (suggestion) => {
        //   const routeParams = {
          //     listingType: 'partners',
          //     type: suggestion.result_type.toLowerCase(),
          //     query: suggestion.name.toLowerCase(),
          //     latitude: this.state.location.latitude,
          //     longitude: this.state.location.longitude,
          //   };
          
          //   if(suggestion.result_type==='GOOGLE') {
            //     routeParams.query = suggestion.type;
            //   }
            
            //   return {
              //     ...suggestion,
              //     route: 'results',
              //     routeParams
              //   }
      // });
      
      // console.log('formattedSuggestions ', formattedSuggestions);
      // this.setState({ suggestions: formattedSuggestions });
      this.setState({ suggestions });
    } catch (e) {
      console.log(e);
      handleError.logError(e);
    }
  }

  enableButton() {
    this.setState({
      searchDisabled: false,
    });
  }

  disableButton() {
    this.setState({
      searchDisabled: true,
    });
  }

  render() {
    return (
      <>
      <header className={'signup'}>
        <div className={'background-image-holder parallax-background'}>
          <div className="background-image" alt="Background" style={{ backgroundImage: `url(${this.state.headerImages[2]})` }} />
        </div>

        <div className={`container container`}>
          <div className={'row'}>
            <div className={'col-md-10 col-md-10 col-md-offset-1 col-sm-12 col-sm-12 text-center'}>
              <h1 className={'textwhite'}>{this.props.t('landing_find_and_book')}</h1>
            </div>
          </div>

          <div className={'row text-center'}>

            <div className={'col-sm-12 col-sm-12 text-center'}>
              <div className={'photo-form-wrapper clearfix'}>
                <div className={'row'}>
                  <div className={'colmd4 col-md-4 col-sm-4 col-sm-4 search_input'}>
                    <ServiceSearch
                      t={this.props.t}
                      getQuery={this.suggest}
                      setService={this.setService}
                      service={this.state.service}
                      setSuggestions={this.setSuggestions}
                      suggestions={this.state.suggestions}
                    />
                    { this.state.showSuggestions &&
                      <div className="suggestion_dropdown">
                        <SuggestionDropdown suggestions={this.state.suggestions} selectService={this.selectService}/>
                      </div>
                    }
                  </div>

                  <div className={'colmd4 col-md-4 col-sm-4 search_input col-sm-4'}>
                    <LocationSearch
                      t={this.props.t}
                      location={this.state.location}
                      setLocation={this.setLocation}
                      state={this.state}
                      enableButton={this.enableButton}
                      disableButton={this.disableButton}
                    />
                  </div>

                  <div className={'colmd4  col-md-4 col-sm-4 col-sm-4 search_input text-center'}>
                    <Link
                      href="#results"
                      route="results"
                      params={{
                        listingType: 'partners',
                        query: this.state.service || ' ' ,
                        type: 'service',
                        latitude: this.state.location.latitude,
                        longitude: this.state.location.longitude,
                      }}
                    >
                      <button type="submit" className={`searchButton ${this.state.searchDisabled ? 'disabled' : 'enabled'} btn btn-primary btn-filled`} disabled={this.state.searchDisabled}>{this.props.t('label_search_button')}</button>
                    </Link>
                  </div>
                </div>
              </div>
              <span className={'textwhite'}>{this.props.t('landing_fast_and_easy')}<br />{this.props.t('landing_new_therapist_or_fav')}</span>
            </div>

          </div>

        </div>

        <style jsx>{styles}</style>
      </header>
      </>
    );
  }
}

LandingHeader.defaultProps = {
  t: "",
  joyride: {},
};

LandingHeader.propTypes = {
  t: PropTypes.func,
  joyride: PropTypes.shape({
    callback: PropTypes.func,
  }),
};
export default LandingHeader;
