import css from 'styled-jsx/css';
import colors from '../../constants/colors';

export default css`
.service_search{
  display: block;
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
  font-size: 14px;
  color: ${colors.landingSearchText};
  background: rgba(255, 255, 255, 0.85);
  border-radius: 50px;
}

.search_wrapper{
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 0 10px;
}

.icon_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
}

.SVG_Icon{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  font-size: 18px;
  color: ${colors.landingSearchText};
}

.input_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  box-sizing: border-box;
}

.input {
  width: 100%;
  box-sizing: border-box;
  border: 0px solid #fff;
  background: unset;
  outline: none;
  color: inherit;
  font-family: Roboto;
}

.input::placeholder{
  color: ${colors.landingSearchText};
}

.home-input{
  margin: 15px 0px;
}
`;
