import css from 'styled-jsx/css';

export default css`
*{
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
.background-image{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.suggestion_dropdown{
    background: rgba(255,255,255,0.85);
    width: 83%;
    position: absolute;
    z-index: 900;
    margin: auto;
}
.searchButton {
    position: relative;
    border: none;
    padding: 20px;
    width: 200px;
    text-align: center;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    outline: none;
}

.searchButton:after {
    content: "";
    background: #ffffff;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px!important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
}

.searchButton:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}
.disabled {
    filter: grayscale(30%);
    cursor: not-allowed;
}

.enabled {
    cursor: pointer;
}

@media only screen and (min-width: 767px){
    .search_input{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}
`;
