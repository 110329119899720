import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import styles from './styles';
import { Link } from '../../../routes';
import LandingHeader from '../../components/LandingHeader/LandingHeader';
import LandingPreFooter from '../LandingPreFooter/LandingPreFooter';
import LandingAppDownload from '../../components/LandingAppDownload/LandingAppDownload';
import SVGIcon from '../../components/SVGIcon/SVGIcon';
import { SearchIcon, landingNetworkIcon, landingFreeIcon, landingProfileIcon, landingFavIcon } from '../../constants/icons';
import LandingSection from '../../components/LandingSection/LandingSection';

class LandingMain extends React.Component {
  toggleDropdownLanguage() {
    if (this.state.languageDropdown === false) {
      this.setState({ languageDropdown: true });
    } else {
      this.setState({ languageDropdown: false });
    }
  }

  render() {
    const { t } = this.props;
    return (

      <>
      <div className={'maincontainer'}>
        <LandingHeader t={t} />

        <section className={'duplicatablecontent'}>

          <div className={'container'}>
            <div className={'row'}>
              <div className={'col-md-12 text-center'}>
                <h1 className="how">{t('landing_how_it_works')}</h1>
              </div>
            </div>

            <div className={'row'}>
              <div className={'colsm6 colmd4'}>
                <div className={'feature feature-icon-large flex_circle_icons_centered'}>
                  <i className="icon icon-magnifying-glass" />
                  <h5>{t('landing_step_1_title')}</h5>
                  <p>{t('landing_step_1_content')}</p>
                </div>
              </div>

              <div className={'colsm6 colmd4'}>
                <div className={'feature feature-icon-large flex_circle_icons_centered'}>
                  <i className="icon icon-browser" />
                  <h5>{t('landing_step_2_title')}</h5>
                  <p>{t('landing_step_2_content')}</p>
                </div>
              </div>

              <div className={'colsm6 colmd4'}>
                <div className={'feature feature-icon-large flex_circle_icons_centered'}>
                  <i className="icon icon-calendar" />
                  <h5>{t('landing_step_3_title')}</h5>
                  <p>{t('landing_step_3_content')}</p>
                </div>
              </div>


            </div>
          </div>

        </section>

        <section className={'duplicatablecontent stat-counters'}>

          <div className={'background-image-holder parallax-background background-image-holder'}>
            <div className={'background-image'} alt="Background" style={{ backgroundImage: 'url(static/imgs/landing_background_1.jpg)' }} />
          </div>

          <div className={'container'}>
            <div className={'row'}>
              <div className={'col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 text-center'}>
                <h1 className="adv">{t('landing_your_advantages')}</h1>
              </div>
            </div>

            <div className={'row flex_circle_icons'}>
              <div className={'col-md-3 colsm6'}>
                <div className={'stat feature feature-icon-large'}>
                  <div className="custom_circle_icon">
                    <div className="svg_circle">
                      <div className="svg_wrapper">
                        <SVGIcon icon={landingNetworkIcon} />
                      </div>
                    </div>
                  </div>
                  <h3>{t('landing_thousands_of_therapists')}</h3>
                </div>
              </div>

              <div className={'col-md-3 colsm6'}>
                <div className={'stat feature feature-icon-large'}>
                  <div className="custom_circle_icon">
                    <div className="svg_circle">
                      <div className="svg_wrapper">
                        <SVGIcon icon={landingFreeIcon} />
                      </div>
                    </div>
                  </div>
                  <h3>{t('landing_without_costs')}</h3>
                </div>
              </div>

              <div className={'col-md-3 colsm6'}>
                <div className={'stat eature feature-icon-large'}>
                  <div className="custom_circle_icon">
                    <div className="svg_circle">
                      <div className="svg_wrapper">
                        <SVGIcon icon={landingProfileIcon} />
                      </div>
                    </div>
                  </div>
                  <h3>{t('landing_your_own_profile')}</h3>
                </div>
              </div>

              {/*
              <div className={`${bootstrapCSS['col-md-3']} ${bootstrapCSS.colsm6}`}>
                <div className={`${themeCSS.stat} ${themeCSS.feature} ${themeCSS['feature-icon-large']}`}>
                  <div className="custom_circle_icon">
                    <div className="svg_circle">
                      <div className="svg_wrapper">
                        <SVGIcon icon={landingFavIcon} />
                      </div>
                    </div>
                  </div>
                  <h3>{t('landing_your_favourites')}</h3>
                </div>
              </div>
              */}
            </div>

          </div>
        </section>
        <LandingSection
          t={t}
          alignment="right"
          header="landing_product_1_header"
          subheader="landing_product_1_subheader"
          content="landing_product_1_content"
          image="static/imgs/landing_product_1.png"
        />
        <LandingSection
          t={t}
          alignment="left"
          header="landing_product_2_header"
          subheader="landing_product_2_subheader"
          content="landing_product_2_content"
          image="static/imgs/landing_product_2.png"
        />
        <LandingSection
          t={t}
          alignment="right"
          header="landing_product_3_header"
          subheader="landing_product_3_subheader"
          content="landing_product_3_content"
          image="static/imgs/landing_product_3.png"
        />
        {/*
        <LandingSection
          t={t}
          alignment="left"
          header="landing_product_4_header"
          subheader="landing_product_4_subheader"
          content="landing_product_4_content"
          image="static/imgs/landing_product_4.png"
        />
        */}
        <section className={'action-banner overlay'}>

          {/* <div className={'background-image-holder'}> */}
            {/* <div className="background-image" alt="Background" style={{ backgroundImage: 'url(img/hero3.jpg)' }} /> */}
          {/* </div> */}

          <div className={'container container'}>
            <div className={'row'}>
              <div className={'col-md-offset-2 col-sm-10 col-sm-offset-1 text-center col-md-8'}>
                <h1 className={'textwhite'}>{t('landing_start_now')}</h1>
                <h2 className={'textwhite'}>{t('landing_enter_your_search')}</h2>
              </div>
            </div>
          </div>

        </section>

        {/* <LandingAppDownload t={t} /> */}
        <LandingPreFooter t={t} />
        <style jsx>{styles}</style>
      </div>
      </>
    );
  }
}

LandingMain.defaultProps = {
  title: null,
  logo: null,
};

LandingMain.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
  logo: PropTypes.string,
};

export default LandingMain;
