import { directoryClient } from '../../api/clients';

export const search = async (query, location) => {
  const { data: centresSuggestions } = await directoryClient.get('/search', { params: { query, location }});

  return centresSuggestions;
};

export default {
  search
};
