import css from 'styled-jsx/css';

export default css`
*{
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .modalContainer {
    padding: 25px 15px;
    box-sizing: border-box;
    height: 500px;
  }

  .contactFrame {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
`;
