import css from 'styled-jsx/css';
import colors from '../../constants/colors';

export default css`
.suggestion_dropdown{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.suggestion_item_container{
  display: flex;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 8px;
  font-family: Roboto;
  font-size: 14px;
  color: ${colors.landingSearchText};
}

.suggestion_item_container:hover{
  background: rgba(231, 76, 60, 0.3);
}
`;
